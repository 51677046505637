.footer-container {
  background: linear-gradient(
    220deg,
    rgba(198, 226, 254, 0.47) -8.75%,
    rgba(198, 226, 254, 0.47) -4.31%,
    rgba(198, 226, 254, 0.47) 0.12%,
    rgba(198, 226, 254, 0.47) 4.56%,
    rgba(199, 226, 254, 0.47) 9%,
    rgba(199, 226, 254, 0.47) 13.43%,
    rgba(201, 226, 255, 0.47) 17.87%,
    rgba(202, 226, 255, 0.47) 22.31%,
    rgba(204, 226, 255, 0.47) 26.74%,
    rgba(206, 226, 255, 0.47) 31.18%,
    rgba(210, 227, 255, 0.47) 35.61%,
    rgba(213, 227, 255, 0.47) 40.05%,
    rgba(218, 227, 255, 0.47) 44.49%,
    rgba(223, 228, 255, 0.47) 48.92%,
    rgba(228, 228, 255, 0.47) 53.36%,
    rgba(234, 229, 255, 0.47) 57.8%,
    rgba(240, 230, 255, 0.47) 62.23%
  );
  padding: 1.5rem 3rem 1.5rem 3rem;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  text-decoration-skip-ink: none;
}
.social-row {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
  padding: 0.5rem;
}
.social-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid black;
  padding-bottom: 1rem;
}
@media (max-width: 767px) {
  .social-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0;
  }
}
@media (max-width: 767px) {
  .social-row {
    justify-content: center;
  }
}
@media (max-width: 577px) {
  .footer-container {
    background: none;
  }
  .footer-socials {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    align-items: center;
    justify-content: center;
  }
}
.footer-socials {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
