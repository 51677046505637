.contact_form {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  padding: 2rem;
}
.btn-submit {
  color: rgba(101, 85, 143, 1);
  padding: 10px 20px 10px 20px;
  border: 2px solid rgba(101, 85, 143, 1);
  border-radius: 20px;
  font-family: "Quicksand";
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-align: left;
  text-decoration-skip-ink: none;
  background-color: transparent;
  text-transform: uppercase;
}
