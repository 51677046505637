.color-dark-grey {
  color: #454545;
}
.w-100vw {
  width: 100vw;
}
.cursor-pointer {
  cursor: pointer;
}
.d-flex-column-center {
  display: flex;
  flex-direction: column;
}
.col-pink {
  background-color: rgba(255, 206, 234, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.col-brown {
  background-color: rgba(188, 127, 83, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.col-darkpink {
  background-color: rgba(100, 63, 73, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.col-darkbeige {
  background-color: rgba(193, 163, 152, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.col-beige {
  background-color: rgba(217, 204, 194, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.border-radious-10 {
  border-radius: 10px;
}
.gap-5r {
  gap: 5rem;
}
@media (max-width: 991px) {
  .gap-5r {
    gap: 1px;
  }
}
